<template>
  <div id="app" :style="{backgroundImage: siteBackground}">
    <StartPage :links="links" />
  </div>
</template>

<script>
  import StartPage from './components/StartPage.vue'
  import links from './assets/links.json'
  import backgrounds from './assets/backgrounds.json'

  export default {
    name: 'App',
    data() {
      return {
        links: links
       };
    },
    components: {
      StartPage
    },
    computed: {
      siteBackground: function() {
        return `url("/img/${backgrounds.sort(() => Math.random() - 0.5)?.[0]}")`;
      }
    }
  }
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}
</style>
