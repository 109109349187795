<template>
  <div id="start-page">
    <div class="container">
      <div class="search-item">
        <form action="https://kagi.com/search" method="GET">
          <input type="text" name="q" v-focus />
          <input type="submit" value="Search Kagi" />
        </form>
      </div>
      <div class="link-list">
        <div
          class="link-set"
          v-for="(value, propertyName) in links"
          :key="propertyName"
        >
          <h2>{{ propertyName }}</h2>
          <ul>
            <li class="link" v-for="link in value" :key="link.url">
              <a :href="link.url">{{ link.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
      </div>
</template>

<script>
export default {
  props: ["links"],
  directives: {
  focus: {
    // directive definition
    inserted: function (el) {
      el.focus()
    }
   }
  }
};
</script>

<style>
.vww__widget {
  background: rgba(200, 200, 200, 0.6);
  margin: auto;
  #color: #000;
}
.container {
  clear: both;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  justify-content: center;
  height: 100vh;
}
.container > div {
  margin-bottom: 10px;
}
.links-item {
  flex-grow: 1;
}
.search-item {
  width: 100%;
}
.link-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(120, 120, 120, 0.9);
  border: 2px solid black;
  border-radius: 30px;
  font-size: .75em;
}

.link-set h2 {
  color: #fff;
}
.link-list ul {
  list-style-type: none;
}

.link-list ul li {
  text-align: left;
}

.link-list a {
  color: #aaacb1;
  text-decoration: none;
  font-size: 1.15em;
  padding-bottom: 2px;
  border-bottom: 1px solid black;
}

.search-item input {
  margin-right: 5px;
}
.vww__daily {
  overflow-y: hidden;
}
</style>
